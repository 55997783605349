 /*
FOR IMP/PRESENT NEWS TIME
.media-desc{
        max-height: 54px !important;
        overflow: hidden !important; 
    }*/
@font-face {
  font-family: 'JameelNooriNastaleeq';
 /* src:url('../resources/fonts/webfont.eot');*/
  src:/*url('../resources/fonts/webfont.woff') format('woff'),*/
      url('../resources/fonts/JameelKhushkhati.ttf') format('truetype');
  /*src: url("../resources/fonts/jameel/JameelNooriNastaleeq.ttf") format("truetype");*/
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 1199px), (max-width: 1199px) and (min-width: 992px), (max-width: 768px) {

    .article-section>div a>div .news-text, .article-section>div a>div .news-time{
        display: none;
    }

    .sports-news .tns-controls [aria-controls]:nth-child(1) {
        top:714px !important;
    }

    .sports-news .tns-controls [aria-controls]:nth-child(2) {
        top:714px !important;
    }
    .head-media a img{

        margin-top: -50px;
    }
    .heading-text{
        font-family: 'urdu-font' !important;
    }
    /*Pagination Css*/
    .main-pagination{
        width: 98% !important;
        padding-right: 2% !important;

    }

    .headline-desc{
        min-width: 76px !important;
        max-height: 76px !important;
    }
    /*Footer Css*/
    footer form input{
        font-family: 'urdu-font';
        width: 100% !important;
        font-size: 12px;
    }
    .website-number{
        font-size: 12px !important;
    }
    .website-email{
        font-size: 11px !important;
    }
    footer .double ul{
        padding-left:10px !important;
    }
    footer ul{
        list-style: none;
    }

    /*Nav Bar Menu Options padding*/
    .mynavbar.mobile li{
        padding-top: 12px;
    }


    /*Search box Mobile Css*/
    .search{
        width: 120px !important;
        margin-top: -38px !important;
    }
    .reversort {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    .reversort > div {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    .currency-converter input.fancy-input {
        width: 80px !important;
    }
    .currency-converter img {
        display: none;
    }
    .home-total {
        font-size: 15px !important;
        margin-top: 13px !important;
    }

}
/*Only For Iphone 5 Footer Interactive Links*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) 
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
{

    .links-heading{
    font-size: 22px !important;
   }
    .sports-news .tns-controls [aria-controls]:nth-child(1) {
        top:714px !important;
    }

    .sports-news .tns-controls [aria-controls]:nth-child(2) {
        top:714px !important;
    }
    .reversort {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    .reversort > div {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    .currency-converter input.fancy-input {
        width: 81px !important;
    }
    .currency-converter img {
        display: none;
    }
    .home-total {
        font-size: 12px !important;
        margin-top: 13px !important;
    }
    .currency li {
        width: 31% !important;
    }

}




/*Desktop Css*/
@media screen and (min-width: 770px) and (max-width: 1440px) {
    .heading-media{
        max-height:279px !important;
        min-height:279px !important;
        height: 279px !important; 
        position:relative !important; 
        overflow:hidden !important;
    }
    .heading-media> a> .b-loaded {
        min-height: 343px !important;

    }
    .latest-p {
        margin-right: 211px;
        margin-top: 7px;
    }
    #top_news {
        max-height: 341px !important;
        min-height: 341px !important;
        height: 341px !important;
    }
    .headline-famous-section .headlines {
        padding-top: 100px;
    }
    /*.dictionary-main {*/
        /*margin-left: 10%;*/
    /*}*/
}
/*Home Page*/

/*Footer Ul Css*/
footer ul{
        list-style: none;
}
 /*For news time direction*/
p, h1{
    direction: rtl !important;
}
.s-heading h1 {
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 7px;
  height: 46px;
  float: right;
  line-height: 32px;
  background: #ffb900;
  color: #2f2f2f;
  font-family: 'urdu-font';
  font-size: 20px;
  margin-bottom: -1px;
}
/*Search Button Css*/
.search-link{
    top: 28px;
}

.search-overlay .search {
    font-family: 'urdu-font';
    direction: rtl;
    position: absolute;
    margin-top: -37px;
    right: 32px;
    z-index: 1500;
    width: 185px;
    height: 34px;
    background: #fff;
    border-radius: 20px;
    border: 0px;

    visibility: hidden;
    opacity: 0;
    
    
}

.search-overlay.active .search {
    visibility: visible;
    opacity: 1; 

    -webkit-transform: scale(1);
    transform: scale(1);
}
.live-search-query::placeholder{
    color: grey;
}
.search-overlay .search .query {
    float: none;
    width: 100%;
    padding: 0 14px;
    height: 34px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: grey;
    background: #fafafa;
    border-radius: 20px;
    border: 0px solid grey;
    font-size: 15px;

}

.search-overlay .search button {
    display: none;
}

.search-for{
    font-family: 'urdu-font';
    direction: rtl;
    padding: 10px 10px 20px 10px;
}

#bbp_search_submit{
    background: #feb927;
    color: #333;
    font-weight: bold;
    
}

/*Search Button Css END*/

/*Pagination Css Start*/

 /* pagination */
.main-pagination {
    font-family: 'urdu-font';
    direction: rtl;
    clear:both;
    text-align: right;
    width: 98%;
    margin: auto !important;
    display: block !important;
    padding-bottom: 10px;

}

.main-pagination .page-numbers, .main-pagination button {
    direction: rtl;
    display: inline-block;
    color: #fff;
    padding: 5px 10px 5px 10px;
    background: #273272;
    margin: 5px 5px;
    line-height: 25px;
    font-size: 18px;
}

.main-pagination .current, .main-pagination .active a, .main-pagination button:hover { 
    color: #fff;
    background: #ffb900; 
    text-decoration: none;
}



/*Pagination Css End*/

/*Main Headings Font Family*/
.site-name , .s-heading p{
    font-family: 'urdu-font' !important;
}




.video-heading-link:hover{
    color:#23527c !important;
}

/*Background of news headings*/
/* TagList Time Css */
.media-news-time{
    color: #ffb900;
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 10px;
    margin-right: 60px;
}
/* Ticker News Text padding*/
.slick-slide> div> div> p{
padding-top: 5px;
margin-right: 5px;

}
.media-des p{
    min-height: 54px;
}
.headline{
    min-height: 65px;
}
a:hover{
    text-decoration: none !important;
}
.slide-text,  .media-caption{
        background: #0C09096e;
        margin-right: 2%;
        margin-left: 2%;
        width: 96% !important;
        
}
.news{
    background: #0C09096e;
    margin-right: 2%;
    margin-left: 2%;
    right: 0 !important;
    width: 96% !important;
}
.media-time{
    padding: 5px 0 5px 0;
}
.news p, .media-caption, .media-meta{
    padding-right: 10px !important;
}
.caption-text, .news-text{
    padding-top: 10px;
}
.media-meta{
    background: #0C09096e;
    margin-right: 0%;
    margin-left: 6%;
    width: 94%;
}
.media-img > a div {
    background: #0C09096e;
    width: 92% !important;
    margin: 0 4% 0 2%; 
}



/*Weather Forecast Css Start*/
.Weather_main{
    
    /*padding: 10px 100px 0 30px;*/
    width: 98%;
    font-family: 'urdu-font';
    direction: rtl;
    padding-bottom: 30px;
    
}
.Weather_main h2{
    font-family: 'urdu-font';
    direction: rtl;
    font-size: 30px;

}
.edit_location{
    color: grey;
    font-size: 15px;
}
.weather_detail_col, .weather_search_col{

    display: inline-block;
}
.weather_detail_col ul{
    
    list-style: none;
    display: inline-block;
}
.weather_text{
    font-size: 20px;
    font-weight: bold;
    padding-right: 15px;
}
.weather_image{
    display: inline-block;
}









/* BEGIN : For Currency Convertor pages */
 .upper-select {
     width:20%;
     height: 2em;
     margin-top : 4px;
     margin-left: 14%;
     border-radius: .25em;
     white-space: nowrap;
 }
 input.fancy-input, span.fancy-input {
     height: 44px;
     width: 125px;
     font-size: 16px;
     padding: 7px;
     border-radius: 5px;
     border: 1px solid #e3e3e3;
     color: #807979;
 }
 select.fancy-select {
     height: 40px;
     background: #666B85;
     color:white;
 }

 input.fancy-input-mobile, span.fancy-input-mobile {
     height: 44px;
     width: 125px;
     font-size: 16px;
     padding: 7px;
     border-radius: 5px;
     border: 1px solid #e3e3e3;
     color: #807979;
 }
 select.fancy-select-mobile {
     height: 40px;
     background: #666B85;
 }

 /* // END: For Currency Convertor pages */

 /* // START : HOME CURRENCY WIDGET */
 .currency select.fancy-select {
     height: 43px;
     background: #666B85;
     color: white;
     width:95%
 }
 .currency li:nth-child(2n) .social-box {
     margin-right: 0px;
 }
 .currency-converter {
     margin-bottom: 30px;
 }
 .currency-converter img {
     height: 25px;
 }
 .currency-converter input.fancy-input {
     width: 82px;
     direction: ltr;
 }
 .currency li {
     width: 31%;
     margin-top: 30px;
     margin: 2px;
     padding-top: 15px;
 }
 .currency .sub-pkr{
     font-size: 9px;
     margin-left: 1px;
 }
 .currency .home-total {
     text-align: center;
     font-size: 16px;
     font-family: 'Roboto-Bold';
     margin-top: 8px;
     direction: ltr !important;
 }
 .currency  select.fancy-select {
     height: 40px;
     background: #ececec;
     color:black;
     border-color: #bdbcbc;
 }
 .keep-in-touch li .social-box .right .count {
     margin-top: 9px;
 }
 /* // END : HOME CURRENCY WIDGET */

/*NEWS TICKER*/
.latest-news button {
    display: none;
}
.footer-search {
    position: absolute;
    right: 8px;
    height: 21px;
    top: 3px;
    width: unset !important;
}
.clock-icon {
    height: 14px;
    margin-bottom: -13px !important;
    width: unset !important;
}
.left-angle {
    height: 17px;
    float: left;
    width: unset !important;
}
.phone-icon , .envelope-icon {
    height: 12.86px;
    width: unset !important;
}

 .share-alt-icon , .envelope-icon1 , .pinterest-icon, .facebook-icon, .twitter-icon {
     height: 19.16px;
     margin-left: 10px;
     width: unset !important;
 }
 .related-news .s-heading h1 {
     padding-right: 10px;
     padding-left: 10px;
 }

 /* translation*/

 .dictionary-main {
     height: 100px;
     font-family: 'JameelNooriNastaleeq';
     width: 100%;
     line-height: unset !important;


 }
 .dictionary-heading {
     background: #352073;
     text-align:center;
     color: #fff;


 }
 .dictionary-heading h1 {
     margin:auto;
     line-height:51px;
     vertical-align:middle;
 }

 .rtu-form {
     margin-top: 23px;
     font-size: 18px;
 }
 .rtu-form input[type="text"] , .rtu-form select{
     width: 100%;
     padding: 10px;
     border: 1px solid #ccc;
     border-radius: 4px;
     resize: vertical;
     line-height: 0px !important;
     direction: rtl;
     margin-left: 10px;

 }
 .rtu-form select {
     background: #2f2f2f;
     color: white;
 }

 .rtu-form input[type=submit] {
     background-color: #fdb933;
     color: #2f2f2f;
     padding: 12px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     float: right;
     width: 100%;
     font-weight: bold;
 }

 .rtu-form .col-25 {
     float: left;
     width: 23%;
     margin-top: 6px;
     margin-left: 10px;
 }

 .rtu-form .col-75 {
     float: left;
     width: 72%;
     margin-top: 6px;
 }
 .rtu-form .col-50 {
     float: left;
     width: 47%;
     margin-top: 6px;
 }

 .rtu-form .col-100 {
     float: left;
     width: 96%;
     margin-top: 6px;
 }

 .dictionary-main1 {
     /*background: #ccedf0;*/
     height: 225px;
     width: 100%;
     font-size: 15px;
     font-family: 'JameelNooriNastaleeq';
     line-height: unset !important;
     color: #333030;
     height: 45px;
     margin-top: 105px;
     text-align: left;

 }

 .dictionary-main1 h1 {
     margin: auto;
     line-height: 46px;
 }
 .translate-error {
     color:red;
 }
 .translate-type {
     color: green;
     margin-left: 13px;
     font-size: 24px;
 }

 /* Translation text area */

  .ui-menu-item-wrapper.ui-state-disabled {
      background-color: #aaa;
      font-weight: bold;
  }

 .right-allign {
     float: right;
 }

 .rtl {
     direction: rtl;
 }
 .options{
     position: absolute;
     top: 380px;
     right : 70px;
     display: none;
     height: auto;
     line-height: 2em;
     padding: 0;
     margin: 0;
     /*overflow: scroll;
     overflow-x: hidden;*/
     border: 1px solid lightgray;
     background: #fff;
     text-align: center;
     width: 70px;
 }
 .options ul{
     height: 200px;
     list-style: none;
     overflow-y: scroll;
 }
 .options li {
     line-height: 2.3;
     font-size: 16px !important;
     width: auto;
     padding-top: unset !important;

 }
 .options li:hover {
     background: #d9f3f7;
     cursor: pointer;

 }
 ul { list-style: none; padding: 6px; margin:0 }
 li { margin:0; padding: 0;}
 li > div:hover { background-color: #eee}

 #input {
     direction:rtl;
     width:47%;
     margin-top: 43px;

 }
 .options .active {
     background: #d9f3f7;
     font-weight: bold;
 }
