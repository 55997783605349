h1,
h2,
h3,
h4,
h5,
h6,
.posts-list .content > a,
.block.posts a,
.gallery-block .carousel .title a,
.navigate-posts .link,
.list-timeline .posts article,
.bbpress.single-topic .main-heading,
#bbpress-forums .bbp-topic-title,
#bbpress-forums .bbp-forum-title { /*font-family: "Roboto", Georgia, serif;*/ }
::selection { background: #2196f3; }

::-moz-selection { background: #2196f3; }

.top-bar, .post-content .modern-quote, .mobile-head { border-top-color: #2196f3; }

.main-color,
.trending-ticker .heading,
.nav-light .menu > li:hover > a,
.nav-light .menu > .current-menu-item > a,
.nav-light .menu > .current-menu-parent > a,
.nav-light .menu li li:hover,
.nav-light .menu li li.current-menu-item,
.nav-light .mega-menu .sub-nav li:hover,
.nav-light .menu .sub-nav li.current-menu-item,
.nav-light .menu li li:hover > a,
.nav-light .menu li li.current-menu-item > a,
.nav-light .mega-menu .sub-nav li:hover > a,
.nav-light .menu .sub-nav li.current-menu-item > a,
.nav-light-search .active .search-icon,
.nav-light-search .search-icon:hover,
.breadcrumbs .location,
.gallery-title,
.section-head.prominent,
.recentcomments .url,
.block.posts .fa-angle-right,
.news-focus .section-head,
.focus-grid .section-head,
.post-meta-b .posted-by a,
.post-content a,
.main-stars,
.main-stars span:before,
.related-posts .section-head,
.comments-list .bypostauthor .comment-author a,
.error-page .text-404,
a.bbp-author-name { color: #2196f3; }

.navigation .menu > li:hover > a,
.navigation .menu > .current-menu-item > a,
.navigation .menu > .current-menu-parent > a,
.navigation .menu > .current-menu-ancestor > a,
.block-head,
.tabbed .tabs-list .active a,
.comment-content .reply,
.sc-tabs .active a,
.nav-dark-b { border-bottom-color: #2196f3; }

.main-featured .cat,
.main-featured .pages .flex-active,
.main-pagination .current,
.main-pagination button:hover,
.block-head .heading,
.cat-title,
.post .read-more a,
.rate-number .progress,
.listing-meta .rate-number .progress,
.review-box .overall,
.review-box .bar,
.post-pagination > span,
.button,
.sc-button-default:hover,
.drop-caps { background: #2196f3; }

.nav-search .search-icon:hover,
.nav-search .active .search-icon { border-color: #2196f3; }

.modal-header .modal-title,
.highlights h2:before,
.post-header .post-title:before,
.review-box .heading,
.main-heading,
.page-title,
.entry-title,
div.bbp-template-notice,
div.indicator-hint,
div.bbp-template-notice.info,
.post-content .wpcf7-not-valid-tip { border-right-color: #2196f3; }

@media only screen and (max-width: 799px) { .navigation .mobile .fa { background: #2196f3; } }

a:hover, 
.highlights h2 a:hover,
.nav-light a:hover,
.nav-light .mega-menu .featured h2 a:hover, 
.listing-meta .author > a { color: #2196f3; }


.cat-3, 
.cat-title.cat-3,
.block-head.cat-text-3 .heading { 
	background: #0a0a0a;
}

.block-head.cat-text-3 {
	border-color: #0a0a0a;
}
				
.cat-text-3, .section-head.cat-text-3 { color: #0a0a0a; }
				 

.navigation .menu > .menu-cat-3:hover > a, .navigation .menu > .menu-cat-3.current-menu-item > a, .navigation .menu > .menu-cat-3.current-menu-parent > a {
	border-bottom-color: #0a0a0a;
}
	
.nav-light .menu > .menu-cat-3:hover > a, 
.nav-light .menu > .menu-cat-3.current-menu-item > a, 
.nav-light .menu > .menu-cat-3.current-menu-parent > a {
	color: #0a0a0a;
}
		


.cat-9, 
.cat-title.cat-9,
.block-head.cat-text-9 .heading { 
	background: #32aca9;
}

.block-head.cat-text-9 {
	border-color: #32aca9;
}
				
.cat-text-9, .section-head.cat-text-9 { color: #32aca9; text-align: right; }
				 

.navigation .menu > .menu-cat-9:hover > a, .navigation .menu > .menu-cat-9.current-menu-item > a, .navigation .menu > .menu-cat-9.current-menu-parent > a {
	border-bottom-color: #32aca9;
}
	
.nav-light .menu > .menu-cat-9:hover > a, 
.nav-light .menu > .menu-cat-9.current-menu-item > a, 
.nav-light .menu > .menu-cat-9.current-menu-parent > a {
	color: #32aca9;
}
		


.cat-10, 
.cat-title.cat-10,
.block-head.cat-text-10 .heading { 
	background: #0a0a0a;
}

.block-head.cat-text-10 {
	border-color: #0a0a0a;
}
				
.cat-text-10, .section-head.cat-text-10 { color: #0a0a0a; }
				 

.navigation .menu > .menu-cat-10:hover > a, .navigation .menu > .menu-cat-10.current-menu-item > a, .navigation .menu > .menu-cat-10.current-menu-parent > a {
	border-bottom-color: #0a0a0a;
}
	
.nav-light .menu > .menu-cat-10:hover > a, 
.nav-light .menu > .menu-cat-10.current-menu-item > a, 
.nav-light .menu > .menu-cat-10.current-menu-parent > a {
	color: #0a0a0a;
}
		


.cat-4, 
.cat-title.cat-4,
.block-head.cat-text-4 .heading { 
	background: #dd3333;
}

.block-head.cat-text-4 {
	border-color: #dd3333;
}
				
.cat-text-4, .section-head.cat-text-4 { color: #dd3333; }
				 

.navigation .menu > .menu-cat-4:hover > a, .navigation .menu > .menu-cat-4.current-menu-item > a, .navigation .menu > .menu-cat-4.current-menu-parent > a {
	border-bottom-color: #dd3333;
}
	
.nav-light .menu > .menu-cat-4:hover > a, 
.nav-light .menu > .menu-cat-4.current-menu-item > a, 
.nav-light .menu > .menu-cat-4.current-menu-parent > a {
	color: #dd3333;
}
		


.cat-12, 
.cat-title.cat-12,
.block-head.cat-text-12 .heading { 
	background: #42aa47;
}

.block-head.cat-text-12 {
	border-color: #42aa47;
}
				
.cat-text-12, .section-head.cat-text-12 { color: #42aa47; }
				 

.navigation .menu > .menu-cat-12:hover > a, .navigation .menu > .menu-cat-12.current-menu-item > a, .navigation .menu > .menu-cat-12.current-menu-parent > a {
	border-bottom-color: #42aa47;
}
	
.nav-light .menu > .menu-cat-12:hover > a, 
.nav-light .menu > .menu-cat-12.current-menu-item > a, 
.nav-light .menu > .menu-cat-12.current-menu-parent > a {
	color: #42aa47;
}
		


.cat-14, 
.cat-title.cat-14,
.block-head.cat-text-14 .heading { 
	background: #3b9590;
}

.block-head.cat-text-14 {
	border-color: #3b9590;
}
				
.cat-text-14, .section-head.cat-text-14 { color: #3b9590; }
				 

.navigation .menu > .menu-cat-14:hover > a, .navigation .menu > .menu-cat-14.current-menu-item > a, .navigation .menu > .menu-cat-14.current-menu-parent > a {
	border-bottom-color: #3b9590;
}
	
.nav-light .menu > .menu-cat-14:hover > a, 
.nav-light .menu > .menu-cat-14.current-menu-item > a, 
.nav-light .menu > .menu-cat-14.current-menu-parent > a {
	color: #3b9590;
}
		


.cat-15, 
.cat-title.cat-15,
.block-head.cat-text-15 .heading { 
	background: #222222;
}

.block-head.cat-text-15 {
	border-color: #222222;
}
				
.cat-text-15, .section-head.cat-text-15 { color: #222222; }
				 

.navigation .menu > .menu-cat-15:hover > a, .navigation .menu > .menu-cat-15.current-menu-item > a, .navigation .menu > .menu-cat-15.current-menu-parent > a {
	border-bottom-color: #222222;
}
	
.nav-light .menu > .menu-cat-15:hover > a, 
.nav-light .menu > .menu-cat-15.current-menu-item > a, 
.nav-light .menu > .menu-cat-15.current-menu-parent > a {
	color: #222222;
}
		


.cat-13, 
.cat-title.cat-13,
.block-head.cat-text-13 .heading { 
	background: #000c19;
}

.block-head.cat-text-13 {
	border-color: #000c19;
}
				
.cat-text-13, .section-head.cat-text-13 { color: #000c19; }
				 

.navigation .menu > .menu-cat-13:hover > a, .navigation .menu > .menu-cat-13.current-menu-item > a, .navigation .menu > .menu-cat-13.current-menu-parent > a {
	border-bottom-color: #000c19;
}
	
.nav-light .menu > .menu-cat-13:hover > a, 
.nav-light .menu > .menu-cat-13.current-menu-item > a, 
.nav-light .menu > .menu-cat-13.current-menu-parent > a {
	color: #000c19;
}