

/*@font-face {*/
  /*font-family: 'Arial';*/
  /*src: url("../resources/fonts/arial/arial.eot");*/
  /*src: url("../resources/fonts/arial/arial.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/arial/arial.woff") format("woff"), url("../resources/fonts/arial/arial.ttf") format("truetype"), url("../resources/fonts/arial/arial.svg#ArialMT") format("svg");*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
/*}*/
/*@font-face {*/
  /*font-family: 'Roboto-Bold';*/
  /*src: url("../resources/fonts/roboto/Roboto-Bold.eot");*/
  /*src: url("../resources/fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../resources/fonts/roboto/Roboto-Bold.woff") format("woff"), url("../resources/fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("../resources/fonts/roboto/Roboto-Bold.svg#Roboto-Bold") format("svg");*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
/*}*/
/*@font-face {*/
  /*font-family: 'JameelNooriNastaleeq';*/
 /*!* src:url('../resources/fonts/webfont.eot');*!*/
  /*src:!*url('../resources/fonts/webfont.woff') format('woff'),*!*/
      /*url('../resources/fonts/JameelKhushkhati.ttf') format('truetype');*/
  /*!*src: url("../resources/fonts/jameel/JameelNooriNastaleeq.ttf") format("truetype");*!*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
/*}*/

@font-face {
  font-family: 'urdu-font';
  /* src:url('../resources/fonts/webfont.eot');*/
  src:/*url('../resources/fonts/webfont.woff') format('woff'),*/
          url('../resources/fonts/urdu-font.woff') format('truetype');
  /*src: url("../resources/fonts/jameel/JameelNooriNastaleeq.ttf") format("truetype");*/
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}
body .container-fluid:not(footer) {
  max-width: 1365px;
}
body .container-fluid {
  max-width: 1365px;
}
body .nav-container > .row {
  max-width: 1200px;
  margin: 0 auto;
}
body p {
  margin: 0;
}
body .p0 {
  padding: 0;
}
body a {
  outline: none;
  text-decoration: none;
  color: #2f2f2f;
}
body .right-content {
  background: #f8f8f8;
}
body .left-content {
  border-right: 1px solid lightgrey;
}
body .border-left {
  border-left: 1px solid lightgrey;
}
body .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.yellow-color {
  color: #ffb900 !important;
}

.content-wrapper {
  margin: 0 auto;
  padding: 0;
}





/*Nav Menu Css Start*/
.content-wrapper nav {
  background: #352073;
  padding: 0;
}
.content-wrapper nav .mynavbar {
  list-style-type: none;
}
.content-wrapper nav .mynavbar li {
  display: block;
  float: right;
  position: relative;
}
.content-wrapper nav .mynavbar li:not(:last-child) {
  margin-left: 23px;
}
.content-wrapper nav .mynavbar li.active:after {
  position: absolute;
  display: block;
  width: 106%;
  content: '';
  height: 2px;
  background-color: #ffb900;
  left: -4%;
}
.content-wrapper nav .mynavbar li.has-dropdown .dropdown {
  display: none;
  position: absolute;
  color: black;
  padding: 0;
  min-width: 170px;
  text-align: right;
  right: 0;
  top: 135%;
  background: #352073;
  z-index: 9999;
}
.content-wrapper nav .mynavbar li.has-dropdown .dropdown li {
  margin: 0;
  display: block;
  float: none;
  padding: 3px 10px;
  border-bottom: 1px solid #fff;
}
.content-wrapper nav .mynavbar li.has-dropdown .dropdown li a:hover {
  opacity: 0.7;
}
.content-wrapper nav .mynavbar.mobile {
  display: block;
  transition: opacity 0.33s;
  margin: 0;
  padding: 0 15px;
  padding-bottom: 10px;
  border-top: 2px solid #ffb900;
  padding-top: 6px;
}
.content-wrapper nav .mynavbar.mobile li {
  float: none;
  margin: 0;
}
.content-wrapper nav a {
  text-decoration: none;
  font-family: 'urdu-font';
  font-size: 19px;
  color: #fff;
}
.content-wrapper nav a:hover {
  opacity: 0.7;
}
.content-wrapper nav .nav-togg {
  height: 55px;
}
.content-wrapper nav .nav-togg .mobile-menu-icon {
  display: none;
  position: relative;
  top: 50%;
  margin-left: 15px;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  line-height: 1;
}
.content-wrapper nav .site-name {
  background: #ffb900;
  padding: 2px 10px;
  font-weight: bold;
  width: 134px;
  height: 55px;
  text-align: center;
  position: relative;
}
/*Nav Menu Css END*/





/*Font Logo of website*/

.content-wrapper nav .site-name a {
  font-family: 'Arial' !important;
  font-size: 22px !important;
  color: #2f2f2f;
  display: inline-block;
  position: absolute;
  top: 50%;
  display: block;
  text-align: center;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  line-height: 1;
}






/*Nav Bar Search icon css*/
.content-wrapper nav .search-link {
  margin-right: 30px;
  height: 55px;
  position: relative;
}
.content-wrapper nav .search-link a {
  position: relative;
  top: 50%;
  display: block;
  text-align: center;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  line-height: 1;
}






/*Latest news main div*/
.latest-news {
  font-family: 'urdu-font';
  text-align: right;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 15px;
  color: #2f2f2f;
  display: none;
}
.latest-news a {
  font-size: 15px;
  color: #2f2f2f;
  text-decoration: none;
}





/*Latest News Row with Full width*/
.latest-news .row {
  max-width: 1200px;
  margin: 0 auto;
}
.latest-news p {
  line-height: 1.3;
}
.latest-news .heading {
  color: red;
}







/*Slider News Div Css Start*/
.multimedia-slider {
  font-family: 'urdu-font';
  overflow: hidden;
}
.multimedia-slider .item {
  /*opacity: 0.4;*/
  height: 408px;
  position: relative;
}
.multimedia-slider .item img {
  height: 100%;
}
.multimedia-slider .item .img {
  height: 100%;
}
.multimedia-slider .item div {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}
/*Newstime Slider News div*/
.multimedia-slider .item div .news-time {
  color: #ffb900;
  font-size: 12px;
  line-height: 26px;
  margin-bottom: 10px;
}
/*Slider News  news heading*/
.multimedia-slider .item div .news-text {
  color: #fff;
  font-size: 21px;
  line-height: 32px;
  overflow: hidden;
}
.multimedia-slider .slick-slide {
  position: relative;
}
.multimedia-slider .slick-slide.slick-active .slide {
  opacity: 1;
}
.multimedia-slider .slick-arrow-control.left {
  left: -16px;
}
.multimedia-slider .slick-arrow-control.right {
  right: -16px;
}
.tns-nav {
  display: none;
}
/*Slider News Css End*/








/*Featured News Div Css Start*/
/*featured news heading*/
.s-heading {
  border-bottom: 2px solid #ffb900;
  text-align: right;
  font-size: 21px;
  font-weight: bold;
  overflow: hidden;
  font-family: 'urdu-font' !important;
}
.s-heading.triangled:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-bottom: 46px solid #ffb900;
  border-left: 35px solid transparent;
  float: right;
  margin-top: 13px;
}
.s-heading p {
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 7px;
  height: 46px;
  float: right;
  line-height: 32px;
  background: #ffb900;
  color: #2f2f2f;
}
.s-heading.triangled p {
  padding-left: 25px;
}

.media-list {
  clear: both;
}
.media-list ul {
  padding: 0;
  margin: 0;
}
.media-list li {
  overflow: hidden;
}
.media-list .media-thumb {
  width: 105px;
  height: 75px;
  margin-bottom: 15px;
  margin-left: 13px;
  float: right;
  line-height: 58px;
}
.media-list .media-thumb .b-loaded {
  width: 100%;
  height: 100%;
}
.media-list .media-thumb .b-lazy {
  width: 100%;
  height: 100%;
}

.media-list .media-detail {
  text-align: right;
}

.headline-famous-section {
  font-family: 'urdu-font';
  max-width: 1500px;
  margin: 0 auto;
  background: #f8f8f8;
}
.headline-famous-section a {
  text-decoration: none;
  color: #2f2f2f;
}
.headline-famous-section a .media-detail {
  text-align: right;
  text-decoration: none;
  color: #2f2f2f;
}
.headline-famous-section a .media-detail .media-desc {
  font-size: 14px;
  line-height: 22px;
  padding-top: 10px;
}
.headline-famous-section a .media-detail .media-time {
  color: red;
  font-size: 12px;
  line-height: 28px;
}
.headline-famous-section ul {
  list-style-type: none;
  overflow: hidden;
}
.headline-famous-section .recents-famous {
  /*padding-top: 100px;*/
  padding-bottom: 100px;
}
.headline-famous-section .recents-famous .mynav {
  height: 46px;
  text-align: center;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
}
.headline-famous-section .recents-famous .mynav li {
  width: 50%;
  text-align: center;
  float: left;
  padding: 0;
  height: 100%;
  cursor: pointer;
}
.headline-famous-section .recents-famous .mynav li span {
  color: #2f2f2f;
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
.headline-famous-section .recents-famous .mynav li.active {
  cursor: not-allowed;
}
.headline-famous-section .recents-famous .mynav li.active div {
  background-color: #ffb900;
}
.headline-famous-section .recents-famous .mynav li:first-child {
  padding-right: 1px;
}
.headline-famous-section .recents-famous .mynav li:last-child {
  padding-left: 1px;
}
.headline-famous-section .recents-famous .mynav li div {
  height: 100%;
  border: 2px solid #ffb900;
  padding: 6px 0;
}
.headline-famous-section .recents-famous .tab-content .tab-pane {
  display: none;
}
.headline-famous-section .recents-famous .tab-content .tab-pane.active {
  display: block;
}
.headline-famous-section .recents-famous .tab-content li {
  overflow: hidden;
}
.headline-famous-section .recents-famous .tab-content li .media-thumb {
  float: right;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 7px;
}

.headline-famous-section .recents-famous .tab-content li .media-thumb .b-loaded {
  height:72px;
  width: 72px;
  border-radius: 50%;
}
.recents-famous .tab-content li {
    width: 311px;
    margin-left: -43px;
}
.headline-famous-section .headlines {
  padding-bottom: 100px;
  border-right: 1px solid lightgrey;
  background: #fff;
}
.headline-famous-section .headlines .s-heading {
  margin-bottom: 30px;
}
.headline-famous-section .headlines .videos-media .heading-media:before {
  background: url("../resources/images/icon/video.png");
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58px;
  height: 58px;
}
.headline-famous-section .headlines .heading-media {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  text-align: center;
}

.headline-famous-section .headlines .heading-media .b-loaded {
  width: 395px;
  width: 100%;
  float: right;
}

.media-caption {
  position: absolute;
  width: 100%;
  text-align: right;
  bottom: 15px;
  right: 0;
  padding-right: 20px;
}
.media-caption .caption-text {
  color: #fff;
  font-size: 21px;
  line-height: 26px;
}
.media-caption .media-time {
  color: #ffb900;
  font-size: 12px;
  line-height: 18px;
}
/*Featured News Css End*/









/*Latest Videos Div Css Start*/
.latest-video {
  background: url("../resources/images/background/bg-news.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /*padding: 100px 45px;*/
  margin: 0 auto;
}
.latest-video ul {
  padding: 0;
  margin: 0;
}
.latest-video .s-heading {
  margin-bottom: 35px;
}
.latest-video a {
  color: #fff;
  text-decoration: none;
}
.latest-video a .media-detail {
  font-family: 'urdu-font';
}
.latest-video a .media-detail .media-desc {
  color: #fff;
}
.latest-video a .media-detail .media-time {
  color: #ffb900;
}
.latest-video .yt-video {
  margin-bottom: 12px;
}
.latest-video .yt-video iframe {
  width: 100%;
}
/*Latest Videos Section Css End*/







/*International & Sports & Business Main Div Css Start*/
.data-sections .left-right {
  border-top: 1px solid lightgrey;
}
.data-sections .slick-arrow-control {
  outline: none;
  float: left;
  background-color: #fff;
  border: 2px solid #ffb900;
  width: 25px;
  height: 25px;
  font-size: 19px;
  color: #ffb900;
  padding: 1px;
  margin-bottom: 12px;
}
.data-sections .slick-arrow-control.right {
  color: #fff;
  background: #ffb900;
}
.data-sections .slick-arrow-control i {
  line-height: 1;
  display: block;
}
.data-sections .slick-arrow-control.left {
  margin-right: 4px;
}
/*International & Sports & Business Main Div Css End*/






/*International News Div Css Start*/
.international-news {
  /* padding-top: 100px; */
  padding-bottom: 100px;
}
.international-news .s-heading {
  margin-bottom: 30px;
}
.international-news .base4 {
  height: 396px;
  overflow: hidden;
}
.international-news .base4 .slide {
  width: 100%;
  height: 396px;
  overflow: hidden;
}
.international-news .base4 .slide.single .media-caption {
  /* bottom: 15px; */
}
.international-news .base4 .slide.tripple .media-caption {
  bottom: 10px;
}
.international-news .base4 .slide.tripple .media-img {
  width: 50%;
  height: 50%;
}
.international-news .base4 .slide.tripple .media-img:first-child {
  width: 100%;
  height: 50%;
}
.international-news .base4 .slide .media-img {
  font-family: 'urdu-font';
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  font-size: 18px;
  line-height: 22px;
}
.international-news .base4 .slide .media-img .caption-text {
  font-size: 19px;
  line-height: 22px;
}
.international-news .base4 .slide .media-img .media-time {
  font-size: 11px;
  line-height: 28px;
}
.international-news .base4 .slide .media-img .img {
    width: 100%;
    height: 100%;
    padding: 5px;
}
.international-news .base4 .slide .media-img .img .b-lazy {
  width: 100%;
  height: 100%;
}

.international-news .base4 .slide .media-img .b-loaded {
  width: 100%;
  height: 100%;
}

/*International News Css End*/







/*Sports News Css Start*/
.sports-news .s-heading {
  margin-bottom: 30px;
}
.sports-news .base3 {
  font-family: 'urdu-font';
  height: 320px;
  overflow: hidden;
}
.sports-news .base3 .item {
  height: 320px;
  padding: 0 7px;
}
.sports-news .base3 .item img {
  margin-bottom: 4px;
}
.sports-news .b-loaded {
  height: 190px;
  width: 100%;
}
.sports-news .base3 .item a, .sports-news .base3 .item a:hover {
  text-align: right;
  font-size: 16px;
  line-height: 27px;
  color: #2f2f2f;
  text-decoration: none;
}
.sports-news .base3 .item a .media-news-time, .sports-news .base3 .item a:hover .media-news-time {
  font-size: 12px;
  line-height: 28px;
  margin-bottom: 3px;
  color: #ffb900;
}
/*Sports News Css End*/







/*Business News Css Start*/
.suggested-news .s-heading {
    margin-bottom: 30px;
}
.suggested-news .media-content {
    position: relative;
  line-height: unset !important;
}
.suggested-news .media-box {
    float: right;
    max-width: 500px;
    overflow: hidden;
}
.suggested-news .media-box a {
    text-decoration: none;
    font-family: 'urdu-font';
    text-align: right;
    font-size: 16px;
    line-height: 27px;
    color: #2f2f2f;
}
.suggested-news .media-box a .media-des {
    margin-bottom: 40px;
}
.suggested-news .media-box a img {

    margin-bottom: 14px;
  line-height: unset !important;
}

.suggested-news .b-loaded {
  height: 190px;
  width: 100%;
}
/*Business News Css End*/


/*Suggested News Css Start*/
.articles-news {
  margin-top: 60px;
  margin-bottom: 60px;
  line-height:2;
}
.articles-news .tns-controls img {
    margin-bottom: 10px;
}
.articles-news .s-heading {
  margin-bottom: 30px;
}
.articles-news .base2 {
  font-family: 'urdu-font';
  overflow: hidden;
  text-align: right;
  height: 320px;
}
.articles-news .base2 .item {
  padding: 0 15px;
  height: 320px;
}
.articles-news .base2 a {
  font-size: 16px;
  line-height: 27px;
  color: #2f2f2f;
  text-decoration: none;
}
.articles-news .base2 a .media-news-time {
  font-size: 12px;
  line-height: 28px;
  margin-bottom: 3px;
  color: #ffb900;
}
.articles-news .base2 img {
  margin-bottom: 10px;
}
.articles-news .b-loaded {
  height: 190px;
  width: 100%;
}
/*Suggested News Css End*/




/*Suggested News Css Start*/
.business-news {
    margin-top: 60px;
    margin-bottom: 60px;
}
.business-news .s-heading {
    margin-bottom: 30px;
}
.business-news .item {
    font-family: 'urdu-font';
    overflow: hidden;
    text-align: right;
    height: 320px;
}
.business-news  .item {
    padding: 0 15px;
    height: 320px;
}
.business-news  a {
    font-size: 16px;
    line-height: 27px;
    color: #2f2f2f;
    text-decoration: none;
}
.business-news a .media-news-time {
    font-size: 12px;
    line-height: 28px;
    margin-bottom: 3px;
    color: #ffb900;
}
.business-news img {
    margin-bottom: 10px;
}
.business-news .b-loaded {
  height: 190px;
  width: 100%;
}
/*Suggested News Css End*/

/* CSS for google ads*/







/*Right Column Css For Word Tags Start*/
.famous-tags {
    font-family: 'urdu-font';
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 30px;
    text-align: right;
}
.famous-tags a {
    text-decoration: none;
    color: #2f2f2f;
}
.famous-tags a:hover {
    opacity: 0.7;
}
/*Right Column Word Cloud Css End*/








/*Right Column Css For Prominent News */
.prominent-news {
    overflow: hidden;
    font-family: 'urdu-font';
    padding-top: 100px;
    text-align: right;
}
.prominent-news .base5 {
    height: 240px;
    overflow: hidden;
}
.prominent-news .base5 .arrow-control {
    line-height: 1;
    border-radius: 70%;
    outline: none;
    border: 1px solid lightgrey;
    width: 33px;
    font-size: 19px;
    padding: 0;
    height: 33px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 22;
    color: #2f2f2f;
    background-color: #fff;
}
.prominent-news .base5 .arrow-control.left {
    left: 20px;
}
.prominent-news .base5 .arrow-control.right {
    right: 20px;
}
.prominent-news .base5 .item {
    position: relative;
    height: 240px;
}
.prominent-news .base5 .item .news-img {
    height: 100%;
}

.prominent-news .base5 .item .b-loaded {
  width: 100%;
  height: 100%;
}
.prominent-news .base5 .item .news {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 15px;
    line-height: 28px;
    color: #ffb900;
}
.prominent-news .base5 .item .news .news-text {
    font-size: 21px;
    line-height: 25px;
    color: #fff;
}
/*Prominent News Slider Css END*/









/*News Detail Page Css Start*/
/*Main News Detail Css Start*/
.article-section {
  text-align: right;
  background: #fff;
  font-family: 'urdu-font';
  font-size: 17px;
  color: #0f0f0f;
  margin-top: 90px;
}
.article-section .head-media {
  margin-bottom: 30px;
  position: relative;
}
.article-section .head-media a {
  font-size: 25px;
  color: #fff;
}
.article-section .head-media img {
  max-width: 100%;
  max-height: 480px;
  width: 100%;
}
.article-section .head-media .media-meta {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.article-section .head-media .media-meta .news-time {
  color: #ffb900;
  font-size: 12px;
}
.article-section .media-secondary {
  margin-top: 45px;
  margin-bottom: 40px;
}
.article-section .media-secondary img {
  width: 100%;
  max-height: 100%;
  max-height: 480px;
}
.article-section p.text {
  margin-bottom: 20px;
   line-height: 2;
   word-spacing: 1px;
   font-size: 18px;
}
.article-content {
    margin-bottom: 20px;
    line-height: 2;
    word-spacing: 1px;
    font-size: 18px;
}

.article-section .heading-text {
  font-family: 'urdu-font' !important;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
}
.article-section p.last {
  font-family: 'Arial';
}
/*Main News Detail Css END*/







/*News Detail Page Single Suggested News(under main news) Css Start*/
.news-like-this {
  font-family: 'urdu-font';
  font-size: 17px;
  margin-top: 40px;
  display: inline-flex;
  direction: rtl;
}
.news-like-this .head {
  color: #fff;
  background: #273272;
  line-height: 1.5;
  padding: 8px 8px;
  width: 120px;
}
.news-like-this .text a {
  color: red;
  text-decoration: none;
  border-bottom: 1px solid red;
}
.news-like-this .text.pull-right {
  margin-right: 10px;
}
/*News Detail Page Single Suggested News(under main news) Css End*/







/*Contact Us Widget Css Start*/
.keep-in-touch {
    overflow: hidden;
    font-family: 'urdu-font';
    padding-top: 100px;
    text-align: right;
}
.keep-in-touch li {
    display: block;
    float: right;
    width: 50%;
    columns: #818181;
}
.keep-in-touch li .social-box {
    height: 80px;
    padding: 20px 0;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}
.keep-in-touch li .social-box div {
    display: inline-block;
    vertical-align: middle;
}
.keep-in-touch li .social-box p {
    line-height: 1;
    margin-top: 4px;
}
.keep-in-touch li .social-box .count {
    font-size: 15px;
    font-family: 'Roboto-Bold';
}
.keep-in-touch li .social-box .name {
    font-size: 15px;
    font-family: 'Arial';
    font-weight: bold;
}
.keep-in-touch li .social-box .right.data {
    margin-right: 5px;
}
.keep-in-touch li .social-box .right.yt {
    color: #ff6c5b;
}
.keep-in-touch li .social-box .right.tw {
    color: #43c9eb;
}
.keep-in-touch li .social-box .right.gp {
    color: #f14133;
}
.keep-in-touch li .social-box .right.fb {
    color: #436feb;
}
.keep-in-touch li .social-box .right .count {
    color: #2f2f2f;
}
.keep-in-touch li:nth-child(2n) .social-box {
    margin-right: 10px;
}

/*Contact Us Widget Css End*/







/*News Detail Page Share news widgets Css Start*/
.social-media-share {
  font-family: 'Arial';
  margin-bottom: 65px;
  margin-top: 55px;
}
.social-media-share a {
  text-decoration: none;
}
.social-media-share .share {
  text-align: right;
  color: #fff;
  background: #95d03a;
  font-size: 16px;
  padding-top: 5px;
  /*font-weight: bold;*/
  padding-right: 15px;
  border-radius: 4px;
  margin-bottom: 7px;
}
.social-media-share .fb {
  background: #3b5998;
}
.social-media-share .pinterest {
  background: #cb2027;
}
.social-media-share .tw {
  background: #55acee;
}
.social-media-share .email {
  background: #7d7d7d;
}
.social-media-share a {
  width: 120px;
  height: 30px;
  padding-left: 15px;
  display: block;
  float: right;
  margin-left: 7px;
}
.social-media-share a i {
  margin-left: 15px;
}
/*News Detail Page Share news widgets Css End*/








/*News Details Page Suggested News in bottom Css Start*/
.related-news {
    padding-top: 90px;
}
.related-news .news-media:before {
    padding: 3px 0;
}
.related-news .news-data {
    margin-bottom: 20px;
}
.related-news .news-list {
    font-family: 'urdu-font';
    text-align: right;
}
.related-news .news-list ul {
    list-style-type: none;
    margin: 0;
}
.related-news .news-list ul .media-data {
    padding: 0 10px;
}
.related-news .news-list ul .media-data p {
    line-height: 1.3;
}
.related-news .news-list ul .news-box {
    padding-bottom: 30px;
    position: relative;
    border: 1px solid lightgray;
}
.related-news .news-list ul .news-box {
    text-decoration: none;
}

.related-news .news-list ul .news-box .headline {
    font-size: 20px;
}
.related-news .news-list ul .news-box .headline-time {
    font-size: 12px;
    color: #ffb900;
    margin-top: 10px;
    margin-bottom: 15px;
}
.related-news .news-list ul .news-box .headline-desc {
    font-size: 21px;
}
.related-news .news-list ul .news-media-img {
    height: 230px;
    margin-bottom: 20px;
  text-align: center;
}
.related-news .news-list ul .news-media-img .b-loaded {
    width: 100%;
    height: 100%;
}
/*News Detail Page Related News in bottom Css End*/

/*News Detail Page Css END*/







/*Category Page Css Start*/
.details-section .famous-tags,
.details-section .keep-in-touch {
  padding: 0;
  padding-top: 25px;
}
.details-section .s-heading {
    margin-bottom: 15px;
}





/*Category Page Related News in Right Column Css Start*/
.other-similar-media-news {
  margin-top: 90px;
  margin-bottom: 25px;
  text-align: right;
  font-family: 'urdu-font';
}
.other-similar-media-news ul {
  list-style-type: none;
}
.other-similar-media-news li {
  overflow: hidden;
}
.other-similar-media-news li .media-thumb {
  float: right;
  margin-bottom: 10px;
  margin-left: 10px;
}
.other-similar-media-news li .media-thumb img {
  width: 86px;
  border-radius: 50%;
}
.other-similar-media-news li a {
  color: #2f2f2f;
  text-decoration: none;
}
.other-similar-media-news li a .media-desc {
  font-size: 15px;
  line-height: 22px;
  padding-top: 10px;
}
.other-similar-media-news li a .media-time {
  color: red;
  font-size: 12px;
  line-height: 28px;
}
/*Category News Related News in Right Column Css End*/







/*Category News Survey Section in Right Column Css Start*/
.surevey {
  font-family: 'urdu-font';
  font-size: 18px;
  text-align: right;
  padding-top: 30px;
}
.surevey .question-section {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
}
.surevey input {
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-left: 4px;
  margin-top: 3px;
}
.surevey .quest {
  margin-top: 10px;
  margin-bottom: 10px;
}
.surevey .vote {
  font-size: 20px;
}
.surevey .vote a {
  color: #2f2f2f;
  text-decoration: none;
}
.surevey .vote span {
  font-weight: bold;
}
.surevey .option {
  position: relative;
  float: right;
  padding: 0 12px;
}
.surevey .option:first-child:after {
  height: 25px;
  content: '';
  border-left: 1px solid lightgray;
  left: 0;
  position: absolute;
  top: 0;
}
/*Category News Survey Section in Right Column Css End*/
/*Category Page Css End*/







/*Footer Css Start*/

.right-content .s-heading {
    margin-bottom: 15px;
}
.right-content ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

/*Footer Links Css*/
.right-content .glossary {
    text-align: right;
    font-family: 'urdu-font';
    padding-top: 100px;
}
.right-content .glossary a {
    color: red;
    font-size: 18px;
    line-height: 32px;
    text-decoration: none;
}
.right-content .glossary .fa {
    float: left;
    color: #818181;
    line-height: 1.4;
}
.right-content .glossary li {
    border-bottom: 1px solid #81818121;
    padding: 5px 0;
}
footer {
  background: #273272;
  padding-bottom: 69px;
  position: relative;
}
footer .row {
  max-width: 1500px;
  margin: 0 auto;
}
footer .links-area {
  padding-top: 50px;
  text-align: right;
}
footer .links-heading {
  color: #ffb900;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 38px;
  font-family: 'urdu-font';
}
footer .contact {
  margin-bottom: 30px;
  color: #fff;
}
footer .contact p:not(.links-heading) {
  font-size: 15px;
  line-height: 1;
  direction: ltr;
  margin-bottom: 12px;
}
footer form {
  text-align: right;
  position: relative;
}
footer form input {
  width: 80%;
  border-radius: 25px;
  outline: none;
  border: 0;
  padding-right: 38px;
  font-weight: bold;
  height: 31px;
  margin-bottom: 20px;
  direction: rtl;
  font-family: urdu-font;
}
footer form .fa-search {
  right: 13px;
  color: #2f2f2f;
  top: 9px;
  position: absolute;
  font-size: 15px;
  font-weight: bold;
}
footer ul {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
footer ul a {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  font-family: 'urdu-font';
  text-decoration: none;
}
footer ul a:hover {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
footer .site-name {
  background: #ffb900;
  width: 135px;
  height: 76px;
  text-align: center;
  padding-top: 26px;
  margin-bottom: 50px;
}
footer .site-name a {
  font-weight: bold;
  font-size: 32px;
  color: #2f2f2f;
  text-decoration: none;
  display: block;
  line-height: 1;
}
footer .double ul {
  float: right;
  padding-left: 35px;
}
footer .copyrights {
  height: 56px;
  padding: 17px 0;
  text-align: center;
  background: #20295f;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16px;
  font-family: 'urdu-font';
  color: #fff;
}
footer .site-detail {
  text-align: right;
  clear: both;
  color: #fff;
  font-size: 16px;
  font-family: 'urdu-font';
  line-height: 27px;
}

/*Footer Css END*/






/*News Source Div Css Start*/
.news-media:before {
    position: absolute;
    top: 0;
    width: 65px;
    height: 30px;
    right: 0;
    display: block;
    background: #fff;
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-family: 'Arial';
    font-weight: bold;
}

.news-media.geo:before {
    content: 'GEO';
    background: #f6931e;
}

.news-media.dunya:before {
    content: 'Dunya';
    background: #6055e0;
}

.news-media.bbc:before {
    content: 'BBC';
    background: #cc0202;
}

.news-media.samaa:before {
    content: 'Samaa';
    background: #038ff4;
}

.news-media.jang:before {
    content: 'Jang';
    background: #000;
}
/*News Source Css End*/








/*Mobile Css*/
@media screen and (max-width: 1199px) {
  .media-news-time{
    margin-right: 0 !important;
  }
  .content-wrapper nav .right-nav {
    width: 100% !important;
    left: unset;
  }
  .content-wrapper nav .nav-togg .mobile-menu-icon {
    display: block;
  }
  .content-wrapper nav .mynavbar {
    display: none;
  }
  .content-wrapper nav .mynavbar li.has-dropdown .dropdown {
    position: relative;
    text-align: left;
    margin-top: 5px;
  }
  .content-wrapper nav .mynavbar li.has-dropdown .dropdown li {
    border-bottom: 0;
    border-top: 1px solid #fff;
  }
  .content-wrapper nav .mynavbar li.active:after {
    display: none;
  }
  .content-wrapper nav .mynavbar li.active a {
    color: #ffb900;
  }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .nav-menu {
    left: 0 !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
    .related-news {
        padding-top: 0;
    }

    .headline-famous-section .recents-famous {
        padding-top: 0;
    }

    .latest-news {
        text-align: center ;
    }

    .multimedia-slider .slide img {
        width: 100%;
    }
    .multimedia-slider .slick-arrow-control.left {
        left: 3px;
    }
    .multimedia-slider .slick-arrow-control.right {
        right: 3px;
    }

    .media-box {
        float: right;
    }

    .videos-media {
        margin-bottom: 15px;
    }
}

/*Mobile Css End*/


/*# sourceMappingURL=styles.css.map */

.business-news .item {
  background: #e9eaec57;
  text-align: center !important;
}

.business-news .img {
  line-height: 235px;
}

.business-news .item p {
  text-align: right;
}

.articles-news .item {
  background: #e9eaec57;
  text-align: center !important;
}

.articles-news .img {
  line-height: 235px;
}

.articles-news .item p {
  text-align: right;
}

.sports-news .item {
  background: #e9eaec57;
  text-align: center !important;
}

.sports-news .img {
  line-height: 235px;
}

.sports-news .item p {
  text-align: right;
}



.suggested-news .media-box {
  background: #e9eaec57;
  text-align: center !important;
}

.suggested-news  .media-content {
  line-height: 235px;
  text-align: center !important;
}

.suggested-news .item p {
  text-align: right;
}

.prominent-news .item {
  background: #e9eaec57;
  text-align: center !important;
}

.prominent-news  .img {
  line-height: 235px;
  height: 100%;
}

.prominent-news .item p {
  text-align: right;
}

.recents-famous .media-thumb {
  width: 92px;
  text-align: center;
  line-height: 86px;
}



.multimedia-slider .item {
  background: #e9eaec57;
  text-align: center !important;
}

.multimedia-slider .img {
  width: 100%;
  text-align: center !important;
  line-height: 310px;
}

.multimedia-slider .item p {
  text-align: right;
}



.international-news .slide .media-img {
  text-align: center;
}

.international-news .slide.single .img {
  line-height: 282px;
}
.text {
  line-height: 1.78;
}

.desktop-ads-header {
  margin-left: 10px;
  margin-top: 10px;
  max-width: 970px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  display: none;
  margin: 0 auto;
}
.desktop-ad-right-news {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0 auto;
}
.desktop-ad-right-top {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0 auto;
  display: none;
}
.mobile-ad-suggesion {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0 auto;
}
.desktop-ad-btw-news {
  max-width: 728px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  display: none;
  margin: 0 auto;
}

.mobile-ad-btw-news {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0 auto;
}

.mobile-ads-header {
  max-width: 320px;
  max-height: 50px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
}


.mobile-ads-footer {
  position: fixed;
  bottom: 5px;
  max-width: 320px;
  max-height: 50px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
}
.desktop-category-header {
  max-width: 728px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  display: none;
  margin: 0 auto;
  position: absolute;
  top: 50px;
}
.mobile-category-header {
  max-width: 320px;
  max-height: 50px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.desktop-category-sidebar {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 30px auto;
  display: none;
}

.desktop-home-header {
  max-width: 970px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  display: none;
  margin: -92px auto 21px;
}
.desktop-home-header-down {
    max-width: 970px;
    max-height: 90px;
    /*background: #428bca;*/
    color: #fff;
    line-height: 90px;
    text-align: center;
    display: none;
    margin: 0px auto;
}
.mobile-home-header {
  max-width: 320px;
  max-height: 50px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0 auto;
}

.desktop-home-top-right {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 30px auto;
  display: none;
    margin-top: 0;
}

.desktop-home-video-top {
  max-width: 970px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
    margin: 0 auto;
    margin-bottom: -80px;
    display: none;
}

.desktop-currency-top {
  max-width: 728px;
  max-height: 90px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 0px auto;
  margin-bottom: -70px;
  margin-top: 12px;
  display: none;
}

.mobile-home-video-top {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 30px auto;
    margin-bottom: -54px;
}
.mobile-home-sports-header {
  max-width: 300px;
  max-height: 250px;
  /*background: #428bca;*/
  color: #fff;
  line-height: 90px;
  text-align: center;
  margin: 30px auto;
}
div#top-to-bottom {

  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
div#top-to-bottom > div {

  display: block;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  margin-top: 15px;
}
@media screen and (min-width: 770px) {

  .desktop-ads-header {
    display: block;
  }
  .desktop-ad-right-top {

    display: block;
  }
  .desktop-ad-btw-news {
    display: block;
  }
  .desktop-category-header {
    display: block;
  }
  .desktop-home-top-right {
    display: block;
  }
  .desktop-home-header {
    display: block;
  }
    .desktop-home-header-down {
        display: block;
    }
  .desktop-home-top-right {
    display: block;
  }
  .desktop-home-video-top {
    display: block;
  }
  .desktop-category-sidebar {
    display: block;
  }
  .desktop-currency-top {
    display: block;
  }
  .mobile-ad-suggesion {
    display: none;
  }
  .mobile-ads-header {
    display: none;
  }
  .mobile-ads-footer {
    display: none;
  }
  .mobile-ad-btw-news {
    display: none;
  }
  .mobile-home-header {
    display: none;
  }
  .mobile-category-header {
    display: none;
  }
  .mobile-home-video-top {
    display: none;
  }
  .mobile-home-sports-header {
    display: none;
  }


}

/* Columns Page*/

.four-columns-list {
  font-size: 15px;
  margin-right: 36px;
}

.four-columns-list ul {
  direction: rtl;
  list-style-type: square !important;
}


.mainHeadingImg {
  width: 100%;
  height: 100%;
}
.mainHeadingHeight {
  height: 408px;
}
.sliderButtons:hover {
  border: 1px solid transparent;
  color: #fff;
  background-color: #ffb900;
  display: flex;
  align-items: center;
  width: 84%;
  justify-content: center;
  border-radius: 100%;
}