@font-face{
    font-family:'urdufamily';
    /* src:url('font/webfont.eot'); */
    src:url('http://cdn.urdupoint.com/daily/font-styles/urdupoint.com-webfont.eot?#iefix') format('embedded-opentype'),
        /* url('../font/webfont.woff') format('woff'), */
        /* url('../font/webfont.ttf') format('truetype'), */
        url('http://cdn.urdupoint.com/daily/font-styles/urdupoint.com-webfont.svg#NafeesRegular') format('svg');
    font-weight:normal;
    font-style:normal;
    line-height:28px;
}



html, body{
	text-align: right;
	/* width: 1180px; */
	margin: 0 auto;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}



/* be compatible with "input" defaults */
button, input[type="submit"] { 
	box-sizing: content-box; 
	-moz-box-sizing: content-box; 
	cursor: pointer; 
	-webkit-appearance: none;
}

/* iOS styling reset */
button, input { border-radius: 0; }

button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/* fix IE scrollbar */
textarea { overflow: auto; }


.marginbottom25 {margin-bottom: 25px;}


.ary {
    background: #950000 !important;
}
.bbc {
    background: #bb1919 !important;
}
.express {
	background: #B92533 !important;
}
.geo {
	background: #f7941e !important;
}
.geonews {
	background: #f7941e !important;
}
.jang {
	background: #333333 !important;
}
.nawaewaqat {
    background: #3E3D3D !important;
}
.urdupoint {
	background: #375d8f !important;
}
.dawn {
	background: #324ca1 !important;
}
.samaa {
	background: #0066ab !important;
}
.voa {
	background: #3b00b8 !important;
}
.dunyanews {
	background: #6559e3 !important;
}
.attachment-main-full {
	min-height: 200px;
}

abbr {
	font-family: sans-serif;
}
